<template>
    <div>
        <v-toolbar dense color="primary" dark extended>
            <v-toolbar-title>Historia clínica</v-toolbar-title>
            <template v-slot:extension>
                <v-spacer/>
                <span v-if="hc.id">Última actualización: {{hc.dateCreated}}</span>
            </template>
        </v-toolbar>

        <v-alert type="warning" text class="ma-3" v-if="!hc.id">
            Este paciente no tiene historia clínica registrada
        </v-alert>
        <v-sheet elevation="2" class="ma-2 rounded-xl" >
            <div
                v-for="(estaHc,id) in partesHc"
                :key="id"
            >
                <v-toolbar dense flat color="blue-grey lighten-3" class="rounded-xl">
                    <v-toolbar-title>
                        {{estaHc.label}}
                    </v-toolbar-title>
                </v-toolbar>
                <div v-html="hc[estaHc.valor]" class="pa-5"></div>
            </div>
            <v-toolbar dense color="primary" dark v-if="hc.id" class="rounded-xl">
                <v-spacer/>
                {{hc.medico}} ({{hc.cedula}})
            </v-toolbar>
        </v-sheet>
    </div>
</template>

<script>
export default {
    mounted(){
        this.cargaHc()
    },
    props:{
        paciente: Number,
    },

    data:()=>({
        loading:false,
        hc:{},

        partesHc:[
            {label:'I. ANTECEDENTES HEREDO FAMILIARES', valor:'hcHeFa'},
            {label:'II. ANTECEDENTES NO PATOLOICOS', valor:'hcNoPa'},
            {label:'III. ANTECEDENTES PERSONALES PATOLOICOS', valor:'hcPa'},
            {label:'IV. PADECIMIENTO ACTUAL', valor:'hcPaAc'},
            {label:'V. INTERROGATORIO POR APARATOS Y SISTEMAS', valor:'hcApSi'},
            {label:'VI. EXPLORACION FISICA', valor:'hcExFi'},
            {label:'VII. ESTUDIO DE LABORATORIO Y GABINETES PREVIOS', valor:'hcLaGa'},
            {label:'VIII. DIAGNOSTICOS', valor:'diagnosticos'},
            {label:'IX. PLAN DE ESTUDIO Y TRATAMIENTOS', valor:'estudioTratamiento'},
            {label:'X. PRONOSTICO', valor:'pronostico'},
        ]
    }),

    methods:{
        async cargaHc(){
            this.loading=true

            try{
                let pac = await this.$http({
                    methos:'GET',
                    url:'/historiaclinica/list',
                    params:{cip:this.paciente}
                })
                this.loading=false
                this.hc=pac.data.root[0]
            }catch(err){
                this.loading = false
                this.$store.dispatch('security/revisaError',err)
            }
        },
    },

}
</script>

<style>

</style>